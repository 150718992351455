import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import styled, { ThemeProvider } from "styled-components";
import { ProjectProvider, ProjectContext } from "./contexts/ProjectContext";
import { ControlProvider, ControlContext } from "./contexts/ControlContext";
import Hyper, { Button } from "./Theme/Hyper";
import { ResourcesProvider } from "./contexts/ResourcesContext";
import { UIProvider, UIContext } from "./contexts/UIContext";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { UserProvider, UserContext } from "./contexts/UserContext";
import { RenderProvider } from "./contexts/RenderContext";
import { VideoProvider } from "./contexts/VideoContext";
import DrawingCanvas from "./components/Drawing/DrawingCanvas";
import theme from "./DisplayTheme/Hyperjson.json";
import { OutputContext, OutputProvider } from "./contexts/OutputContext";
import BG from "./Images/BG.PNG";
import HyperLogo from "./Images/HyperLogo.png";
let inputData;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "")};
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 0.5em;
  justify-content: ${(props) => (props.center ? "center" : "")};
`;
const PageButton = styled(Button)`
  margin-right: 5px;
  cursor: pointer;
  :hover {
    background-color: #d20a3e;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
let is_output = window.document.location.pathname !== "/";
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  function clear() {
    setItems([]);
    // setCurrentItem(null);
  }

  window.render =
    (window.ENV?.REACT_APP_RENDER ||
      // eslint-disable-next-line eqeqeq
      process.env.REACT_APP_RENDER) == "true";

  window.showFPS = getParameterByName("showFPS");
  window.project = getParameterByName("project");
  window.uhd = getParameterByName("4k");
  window.project_server =
    window.ENV?.REACT_APP_PROJECT_SERVER ||
    process.env.REACT_APP_PROJECT_SERVER;
  window.engine_url =
    getParameterByName("engine_server") ||
    window.ENV?.REACT_APP_ENGINE_SERVER ||
    process.env.REACT_APP_ENGINE_SERVER;
  window.command_server =
    getParameterByName("command_server") ||
    window.ENV?.REACT_APP_COMMAND_SERVER ||
    process.env.REACT_APP_COMMAND_SERVER;
  window.render_server =
    getParameterByName("render_server") ||
    window.ENV?.REACT_APP_RENDER_SERVER ||
    process.env.REACT_APP_RENDER_SERVER;
  window.useRAF =
    getParameterByName("useRAF") ||
    window.ENV?.REACT_APP_USE_RAF ||
    process.env.REACT_APP_USE_RAF;
  const [project, setProject] = useState();

  useEffect(() => {
    try {
      let input_canvas = document.getElementById("input");
      input_canvas.style.position = "absolute";
      //input_canvas.style.opacity = 0;
      input_canvas.width = 960;
      input_canvas.height = 540;
      window.input_ctx = input_canvas.getContext("2d");
      window.input_canvas = input_canvas;
    } catch (err) {}
    try {
      let output_canvas = document.getElementById("output");
      output_canvas.style.position = "absolute";
      //input_canvas.style.opacity = 0;
      output_canvas.width = 1920;
      output_canvas.height = 1080;
      window.output_ctx = output_canvas.getContext("2d");
      window.output_canvas = output_canvas;
    } catch (err) {}
  }, []);

  function undo() {
    let newItems = items;
    newItems = newItems.slice(0, -1);
    setItems(newItems);
    setCurrentItem(null);
  }

  return (
    <div className="App">
      <ThemeProvider
        theme={{
          ...theme?.theme,
          scrubber:
            window.ENV?.REACT_APP_PEN_COLOUR ||
            process.env?.REACT_APP_PEN_COLOUR,
        }}
      >
        <UIProvider>
          <UserProvider>
            <RenderProvider>
              <ControlProvider>
                <ProjectProvider>
                  <ResourcesProvider>
                    <OutputProvider>
                      <Router>
                        <Switch>
                          <Route path="/Output">
                            <Output />
                          </Route>
                          <Route path="/">
                            <VideoProvider>
                              <div
                                style={{
                                  backgroundColor: "rgb(14, 18, 25)",
                                  height: "100vh",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      width: "1920px",
                                      height: "1080px",
                                    }}
                                  ></div>
                                  {/* <img
                                    style={{
                                      position: "absolute",
                                      width: "1920px",
                                      height: "1080px",
                                    }}
                                    src={BG}
                                    width="1920"
                                    height="1080"
                                    alt="bg"
                                  /> */}
                                  <div id="scale">
                                    <iframe
                                      style={{
                                        position: "absolute",
                                        width: "1920px",
                                        height: "1080px",
                                      }}
                                      width="1920"
                                      height="1080"
                                      src="https://www.youtube.com/embed/LmqBbPwCy8o?autoplay=1&mute=1"
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                      allowfullscreen
                                      autoplay="true"
                                      mute="true"
                                    ></iframe>
                                    <canvas id="input"></canvas>
                                    <DrawingCanvas
                                      items={items}
                                      setItems={setItems}
                                      currentItem={currentItem}
                                      setCurrentItem={setCurrentItem}
                                    />
                                    <img
                                      src={HyperLogo}
                                      style={{
                                        position: "absolute",
                                        left: "800px",
                                        bottom: "-1290px",
                                      }}
                                      height="80"
                                      alt="Logo"
                                    />
                                  </div>
                                  <Sidebar undo={undo} clear={clear} />{" "}
                                </div>
                              </div>
                            </VideoProvider>
                          </Route>
                        </Switch>
                      </Router>
                    </OutputProvider>
                  </ResourcesProvider>
                </ProjectProvider>
              </ControlProvider>
            </RenderProvider>
          </UserProvider>
        </UIProvider>
      </ThemeProvider>
    </div>
  );
}

function Output() {
  const { items } = useContext(OutputContext);

  return <DrawingCanvas items={items} />;
}
export default App;
